/**
 * Product overview sticky and horizontal scrollable navigation.
 *
 * @author Jason Koolman <https://github.com/Jasonkoolman>
 */
var NavBar = (function($, window, document) {
    var $header = $('#header');
    var $nav = $('#nav');
    var $navFrame = $nav.find('.frame');
    var $navItems = $navFrame.find('li');
    var $navSlider;

    /**
     * Initialize the use of the NavBar.
     */
    var init = function () {
        // Horizontal sliding functionality
        $navSlider = new Sly($navFrame, {
            smart: 1,
            horizontal: 1,
            itemNav: 'centered',
            activateOn: 'click',
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            scrollSource: null,
            speed: 600,
            clickBar: 1
        }).init();

        bindListeners();
    };

    /**
     * Binds the listeners.
     */
    var bindListeners = function () {
        // Clickable navigation
        $navItems.click(function(e) {
            var category = $(this).data('category');
            var $target = $('#' + category);

            // Set the location hash
            window.location.hash = category;

            // Scroll to category
            $('html, body').scrollTop($target.offset().top);

            e.preventDefault();
        });

        // Listen to category scroll events
        var $productCategories = $('.product-category', '#content');

        $productCategories.visibility({
            continuous: true,
            offset: $header.height() + $nav.height(),
            onPassing: function(data) {
                var $element = $(this);

                // Check if the category being viewed is not active yet
                if ( ! $element.hasClass('active')) {
                    var category = $element.data('category');
                    var $target = $navItems.filter('[data-category="' + category + '"]');

                    // Activate the category button
                    $navSlider.activate($target);

                    // Mark the new category as active
                    $productCategories.removeClass('active').filter($element).addClass('active');
                }
            }
        });

        // Resize event
        $(window).resize(function() {
            $navSlider.reload();
        });
    };

    // Public methods
    return {
        init: init
    };
})(jQuery, window, document);