/**
 * Lightweight content slider.
 *
 * @author Jason Koolman <https://github.com/Jasonkoolman>
 */
var ContentSlider = (function($, window) {
    var $slider    = $('[data-slider]');
    var $container = $slider.find('.container');
    var $slides    = $container.find('.slide');
    var slideWidth = $slider.width(), sliderTimer;
    var sliding = false;

    /**
     * Initializes the use of the content slider.
     */
    var init = function() {
        setTimer();

        $(window).resize(function() {
            slideWidth = $slider.width();
            slide(0, true);
        });

        $slider.find('.button').click(function(e) {
            if ( ! sliding) {
                slide($(this).hasClass('next') ? 'next' : 'prev', true);
            }
            e.preventDefault();
        });
    };

    /**
     * Navigate to a slide.
     *
     * @param direction
     * @param resetTimer
     */
    var slide = function(direction, resetTimer) {
        var position = $container.position();
        var $activeSlide = $slides.filter('.active'),
            $prevSlide = $activeSlide.prev(),
            $nextSlide = $activeSlide.next();

        if (resetTimer) { setTimer(); }

        if (direction === 'prev') {
            if ($prevSlide.length) {
                $activeSlide.removeClass('active');
                $prevSlide.addClass('active');
                animate(position.left += slideWidth);
            } else {
                slide($slides.length-1);
            }
        } else if (direction === 'next') {
            if ($nextSlide.length) {
                $activeSlide.removeClass('active');
                $nextSlide.addClass('active');
                animate(position.left -= slideWidth);
            } else {
                slide(0);
            }
        } else if ( ! isNaN(direction)) {
            var $targetSlide = $slides.eq(direction);
            if ($targetSlide.length) {
                $activeSlide.removeClass('active');
                $targetSlide.addClass('active');
                animate(-$targetSlide.position().left);
            }
        }
    };

    /**
     * Animate the slider to the given position.
     *
     * @param position
     */
    var animate = function(position) {
        sliding = true;
        $container.animate({
            left: position
        }, {
            duration: 600,
            complete: function() {
                sliding = false;
            }
        });
    };

    /**
     * (Re)Set the timer.
     */
    var setTimer = function() {
        if (sliderTimer) { clearInterval(sliderTimer); }

        sliderTimer = setInterval(function () {
            slide('next');
        }, 5000);
    };

    return {
        init: init,
        slide: slide
    }
})(jQuery, window);