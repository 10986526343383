/**
 * Product overview.
 *
 * @author Jason Koolman <https://github.com/Jasonkoolman>
 * @author Atze Teppema <https://github.com/atzeteppema>
 */

var cropper = null;

var ProductOverview = (function ($, window, document) {
    var $products = $('.products .product', '#content');
    var $cart = $('.cart.button', 'header');
    var $gift = $('#gift');
    var $egv = $('#egv');
    var $body = $('body');
    var chevronsAdded = false;

    /**
     * Initialize the use of the product overview.
     */
    var init = function () {
        try {
            // Active product
            if (document.location.hash) {
                var openVideo = false;
                var openEtiket = false;
                var stop = false;

                var hash = document.location.hash.split('_');
                if (hash.length < 2) {
                    stop = true;
                }

                if (stop === false && hash.length === 3 && hash[2] === 'video') {
                    openVideo = true;
                    hash = [hash[0], hash[1]];
                }

                if (stop === false && hash.length === 3 && hash[2] === 'etiket') {
                    openEtiket = true;
                    hash = [hash[0], hash[1]];
                }

                if (stop === false) {
                    var plu = hash.splice(hash.length - 1) * 1;
                    if (!Number.isInteger(plu) || plu <= 0) {
                        stop = true;
                    }
                }

                if (stop === false) {
                    var category = hash.join('-').replace('#', '');
                    var $category = $('.product-category[data-category="' + category + '"]');
                    if ($category.length !== 1) {
                        stop = true;
                    }
                }

                if (stop === false) {
                    var $activeProduct = $('.product[data-plu="' + plu + '"]', $category);
                    if ($activeProduct.length !== 1) {
                        stop = true;
                    }
                }

                if (stop === false) {
                    $activeProduct.addClass('product-active');

                    setTimeout(function () {
                        var h = $('#header').height() + $('#nav').height() + 20;
                        var offsetTop = $activeProduct.offset().top;
                        window.scrollTo(window.scrollX, offsetTop - h);
                    }, 350);

                    if (openVideo) {
                        setTimeout(function () {
                            $('.product-thumbnail .toggle-modal', $activeProduct).trigger('click');
                        }, 500);
                    }

                    if (openEtiket) {
                        setTimeout(function () {
                            $('a.etiket', $activeProduct).trigger('click');
                        }, 500);
                    }
                }
            }
        } catch (e) {
            window.console && console.log(e);
        }

        try {
            // Product details
            $products.find('.toggle-details').click(function (e) {
                e.preventDefault();

                var $el = $(this);
                var $details = $el.siblings('.product-details');

                try {
                    if ($details.css('display') !== 'block') {
                        _gaq.push(['_trackEvent', 'Product details', 'Open', $el.closest('.product').data('plu')]);
                    }
                } catch (e) {
                    window.console && console.log(e);
                }

                $el.find('i').toggleClass('remove', 'info');
                $details.transition('scale', 300);
            });
        } catch (e) {
            window.console && console.log(e);
        }

        try {
            // Add to cart
            $products.find('.order-button').click(function (e) {
                if (this.tagName === 'BUTTON') {
                    return;
                }

                e.preventDefault();

                var $button = $(this);
                var $product = $button.closest('.product');

                if ($product.find('.etiket').length) {
                    $product.find('.etiket').trigger('click');
                } else {
                    addProductToCart($product, 0);
                }
            });
        } catch (e) {
            window.console && console.log(e);
        }

        try {
            // Product video modal
            $products.find('.toggle-modal').click(function (e) {
                showVideo($(this).closest('.product'));
                e.preventDefault();
            });
        } catch (e) {
            window.console && console.log(e);
        }
        try {
            $products.find('.toggle-video').click(function (e) {
                showVideo($(this).closest('.product'));
                e.preventDefault();
            });
        } catch (e) {
            window.console && console.log(e);
        }

        try {
            // Rect @ mobile
            var factor = 0;
            var width = $('#page').width();
            if (width <= 543) {
                factor = .7;
            } else if (width <= 768) {
                factor = .8;
            }
            if (factor) {
                $('rect').each(function () {
                    var $rect = $(this);
                    var width = $rect.attr('width') * factor;
                    var x = 150 - width;
                    $rect.attr('width', width);
                    $rect.attr('x', x);
                });
            }
        } catch (e) {
            window.console && console.log(e);
        }

        initEtiketModal();

        // Chevrons
        setTimeout(function () {
            addProductChevrons();
        }, 100);

        setEvents();
    };

    var addProductChevrons = function () {
        try {
            if (chevronsAdded) {
                return;
            }
            if (!$('.products', '#content').is(':visible')) {
                return;
            }

            chevronsAdded = true;

            $('.title', $products).each(function () {
                var prepend = false;
                var title = this;
                var $title = $(this);

                if (title.scrollWidth > title.offsetWidth) {
                    prepend = true;
                } else {
                    var description = $title.next()[0];
                    if (description && description.scrollHeight > description.offsetHeight) {
                        prepend = true;
                    }
                }
                if (prepend) {
                    $title.prepend('<i class="icon chevron down"></i>');
                }
            });
        } catch (e) {
            window.console && console.log(e);
        }
    };

    var initEtiketModal = function () {
        try {
            // etiket ontwerpen
            var $etiketModal = $('.etiket-modal');
            var $etiketPhoto = $('.etiket-photo', $etiketModal);
            var $etiketText = $('.etiket-text', $etiketModal);
            var $etiketImg = $('.etiket-img', $etiketModal);
            var $etiketExample = $('.etiket-example', $etiketModal);
            var $etiketTextarea = $('textarea[name="text"]', $etiketModal);
            // var cropper = null;

            var textColor = 'black';
            var textAlign = 'left';
            var textSize = 30;
            var textRotate = 0;
            var textLeft = 0;
            var textTop = 0;

            $etiketTextarea.on('keyup', function () {
                $etiketExample.hide();

                var html = this.value.replace(/\n/g, '<br>').replace(/ /g, '&nbsp;');
                $etiketText.html(html);
            });

            $etiketModal.on('change', '.text-color', function () {
                textColor = '#' + this.value;
                $etiketText.css('color', textColor);
            });

            $etiketModal.on('change input', '.text-size', function () {
                $(this).next().text(this.value);

                textSize = this.value;

                $etiketText.css('font-size', this.value + 'px');
            });

            $etiketText.data('left', 0);
            $etiketText.data('top', 0);
            var etiketPhotoWidth;
            var etiketPhotoHeight;

            $etiketText.on({
                'mousedown': function (event) {
                    $etiketText.data('mousedown', true);
                    $etiketText.data('pageX', event.pageX);
                    $etiketText.data('pageY', event.pageY);
                },
                'mousemove': function (event) {
                    if (!$etiketText.data('mousedown')) {
                        return;
                    }

                    if (!etiketPhotoWidth) {
                        etiketPhotoWidth = $etiketPhoto.width();
                    }

                    if (!etiketPhotoHeight) {
                        etiketPhotoHeight = $etiketPhoto.height();
                    }

                    var movedX = event.pageX - $etiketText.data('pageX');
                    textLeft = $etiketText.data('left') + movedX;
                    textLeft = Math.min(etiketPhotoWidth - $etiketText.width(), textLeft);
                    textLeft = Math.max(0, textLeft);
                    $etiketText.css('left', textLeft);
                    $etiketText.data('left', textLeft);
                    $etiketText.data('pageX', event.pageX);

                    var movedY = event.pageY - $etiketText.data('pageY');
                    textTop = $etiketText.data('top') + movedY;
                    textTop = Math.min(etiketPhotoHeight - $etiketText.height(), textTop);
                    textTop = Math.max(0, textTop);
                    $etiketText.css('top', textTop);
                    $etiketText.data('top', textTop);
                    $etiketText.data('pageY', event.pageY);
                },
                'mouseup': function (event) {
                    $etiketText.data('mousedown', false);
                }
            });
            $etiketPhoto.on('mouseout', function () {
                $etiketText.data('mousedown', false);
            });

            $etiketModal.on('click', '.add-photo', function (event) {
                event.preventDefault();

                $('input[name="photo"]', $etiketModal).trigger('click');
            });

            $etiketModal.on('click', '.cropper-buttons .button', function () {
                var $btn = $(this);
                switch ($btn.data('method')) {
                    case 'rotate':
                        if ($btn.data('option') === 'refresh') {
                            cropper.rotateTo(0);
                        } else {
                            var degrees = $btn.prev().val() ? $btn.prev().val() : 5;
                            cropper.rotate(degrees);
                        }
                        break;
                    case 'zoom':
                        if ($btn.data('option') === 'refresh') {
                            cropper.zoomTo(1);
                        } else {
                            var ratio = $btn.data('option');
                            cropper.zoom(ratio);
                        }
                        break;
                }
            });

            $etiketModal.on('change', 'input[name="photo"]', function () {
                try {
                    var input = this;
                    if (input.files && input.files[0]) {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            if (cropper) {
                                cropper.destroy();
                            }

                            $etiketExample.hide();

                            $etiketImg.html('<img alt="" src="' + e.target.result + '">');

                            var image = $etiketImg.children()[0];

                            cropper = new Cropper(image, {
                                aspectRatio: 165 / 90,
                                autoCrop: 0,
                                autoCropArea: 1,
                                dragMode: 'move',
                                viewMode: 0
                            });

                            $('.cropper-buttons', $etiketModal).show();
                        };
                        reader.readAsDataURL(input.files[0]);
                    }
                }
                catch(err) {
                }
            });

            $etiketModal.on('click', '.btn-shop', function (event) {
                if (!$etiketTextarea.val() && !$etiketImg.html()) {
                    // niks
                    return;
                }

                var data = {
                    'actie': 'etiket',
                    'product': $('input[name="product"]', $etiketModal).val(),
                    'text': $etiketTextarea.val(),
                    'text_color': textColor,
                    'text_align': textAlign,
                    'text_size': textSize,
                    'text_rotate': textRotate,
                    'text_top': textTop,
                    'text_left': textLeft,
                    'img': null,
                    'cropper_data': null
                };
                if (cropper) {
                    cropper.crop();
                    cropper.setCropBoxData({ left: 0, top: 0, width: 487, height: 267 });

                    data.img = cropper.getCroppedCanvas().toDataURL('image/jpeg');
                    data.cropper_data = cropper.getData(true);

                    var canvasData = cropper.getCanvasData();
                    data.cropperZoomRatio = canvasData.width / canvasData.naturalWidth;

                    cropper.clear();
                }

                $.post(AJAX, data, function (data) {
                    if (data && data.artikelId) {
                        if (data.toegevoegd) {
                            if (TOTAALAANTAL == 0) {
                                $('#button-inloggen').addClass('toggled');
                            }

                            TOTAALAANTAL = data.aantal;
                            TOTAALBEDRAG = data.totaalBedrag;

                            $cart.find('.icon').transition('tada');
                            $cart.find('.label').html(data.aantal);

                            $etiketModal.modal('hide');
                        } else {
                            // ???
                        }
                    } else if (data && data.message) {
                        alert(data.message);
                    } else {
                        // ???
                    }
                });
            });

            $('#content').on('click', '.etiket', function (event) {
                event.preventDefault();

                // if (this.href.indexOf('-etiket') > 0) {
                //     document.location.hash = this.href.substring(this.href.indexOf('#'));
                // }

                var $product = $(this).closest('.product');

                $etiketModal.modal({
                    context: '#page',
                    transition: 'vertical flip',
                    dimmerSettings: {
                        opacity: 0.5
                    },
                    onVisible: function () {
                        // this = etiket-modal
                        // todo; reset things?

                        etiketModalOnVisible($etiketModal, $product.data('id'));
                    },
                    onHidden: function () {
                        // ???
                    }
                }).modal('show');
            });
        } catch (e) {
            window.console && console.log(e);
        }
    };

    var etiketModalOnVisible = function ($etiketModal, productId) {
        $('input[name="product"]', $etiketModal).val(productId);

        if (!$etiketModal.data('inited')) {
            $etiketModal.data('inited', true);

            var $etiketPhoto = $('.etiket-photo', $etiketModal);
            var $etiketImg = $('.etiket-img', $etiketModal);

            var w = $etiketPhoto.outerWidth();

            if (w < 489) {
                // 489 x 402 = w x h
                var h = 402 * w / 489;
                $etiketPhoto.css('min-height', h);
                $etiketImg.css('min-height', h);

                $etiketPhoto.css('width', w);
                $('.etiket-container', $etiketModal).css('overflow-x', 'scroll');
            }
        }

        var $etiketTextarea = $('textarea[name="text"]', $etiketModal);
        if ($etiketTextarea.val()) {
            $etiketTextarea.trigger('keyup');
        }
        $etiketModal.find('.text-color').trigger('change');
    };

    var setEvents = function () {
        $body.on('click', '.star.icon', function (e) {
            var siblings = $(e.target).nextAll('.star.icon').length;
            var rating = siblings + 1;
            var $ratingContainer = $(this).closest('.rating-container');
            var productId = $ratingContainer.data('id');

            var $product = $products.filter('.product[data-id="' + productId + '"]');
            if (!$product.length) {
                return false;
            }

            $product.data('rating', rating);

            setRatingStars(rating, $ratingContainer);

            addProductToCart($product, rating);
        });

        $body.on('click', '.title, .description', function (e) {
            var $chevron = $(this);
            $chevron.closest('.product').toggleClass('open');
        });

        // video's in pakket modals
        $body.on('click', '.show-pakket-video', function (event) {
            event.preventDefault();

            var $btn = $(this);
            var videoId = $btn.data('video-id');

            $('.pakket-video-modal').modal({
                allowMultiple: true,
                context: '#page',
                transition: 'vertical flip',
                dimmerSettings: {
                    opacity: 0.5
                },
                onVisible: function () {
                    // Responsive video
                    var $container = $(this).find('.video-container'),
                        $video = $container.find('iframe'),
                        newWidth = $container.width(),
                        aspectRatio = $video.attr('height') / $video.attr('width'),
                        src = 'https://www.youtube-nocookie.com/embed/' + videoId + '?rel=0&showinfo=0&enablejsapi=1&autoplay=1';

                    $video.removeAttr('height').removeAttr('width');
                    $video.width(newWidth).height(newWidth * aspectRatio);
                    $video.attr('src', src);

                    $video[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*'); // Play the video
                },
                onHidden: function () {
                    var $container = $(this).find('.video-container'),
                        $video = $container.find('iframe');

                    $video[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*'); // Stop the video
                }
            }).modal('show');
        });
    };

    var addProductToCart = function ($product, rating) {
        if (TOTAALAANTAL == 0) {
            $('#button-inloggen').addClass('toggled');
        }

        $cart.find('.icon').transition('tada');

        if ($gift.length) {
            $gift.addClass('hover').delay(1000).queue(function () {
                $gift.removeClass('hover').dequeue();
            });
        }

        var type = '';
        if ($product.closest('#scherpe-aanbiedingen').length) {
            type = 'scherpe-aanbieding'
        } else if ($egv.length) {
            type = 'egv';
        }

        var data = {'artikel_id': $product.data('id'), 'actie': 'toevoegen', 'rating': rating, 'type': type};
        $.post(AJAX, data, function (data) {
            TOTAALAANTAL = data.aantal;
            TOTAALBEDRAG = data.totaalBedrag;

            $cart.find('.label').html(data.aantal);

            if ($gift.length) {
                $gift.find('span').html(price(data.gratisVuurwerkBedrag));
            }

            toggleCartButtons(data.ww, data.egv);

            setEgvInfo(data.gratisVuurwerkBedragOver, data.gratisVuurwerkBedragTeveel);

            buildEgvRows(data.egv);
        }).fail(function (data) {
            window.console && console.log(data);
        });
    };

    var showVideo = function ($product) {
        if ($product.data('package') === true) {
            showPakketModal($product.data('id'));
            var $ratingContainer = $('.rating-container', '.pakket-modal');
        } else if ($product.data('kamerplant') === true) {
            showPakketModal($product.data('id'));
            var $ratingContainer = $('.rating-container', '.pakket-modal');
            $ratingContainer.parent().hide();
        } else {
            showModal($product.data('video-id'));
            var $ratingContainer = $('.rating-container', '.product-modal');
            $ratingContainer.parent().show();
        }

        $ratingContainer.data('id', $product.data('id'));

        var rating = $product.data('rating');
        setRatingStars(rating, $ratingContainer);
    };

    var setRatingStars = function (rating, $ratingContainer) {
        $('.star.icon', $ratingContainer).css('color', '');
        if (rating) {
            $('.star.icon', $ratingContainer).slice(-1 * rating).css('color', '#ffeb00');
        }
    };

    /**
     * Show the product modal.
     */
    var showModal = function (videoId) {
        if ($('body').hasClass('ios8')) {
            var src = 'https://www.youtube.com/watch?v=' + videoId + '&rel=0&showinfo=0&autoplay=1';
            window.open(src);
            return;
        }

        $('.product-modal').modal({
            context: '#page',
            transition: 'vertical flip',
            dimmerSettings: {
                opacity: 0.5
            },
            onVisible: function () {
                // Responsive video
                var $container = $(this).find('.video-container'),
                    $video = $container.find('iframe'),
                    newWidth = $container.width(),
                    aspectRatio = $video.attr('height') / $video.attr('width'),
                    src = 'https://www.youtube-nocookie.com/embed/' + videoId + '?rel=0&showinfo=0&enablejsapi=1&autoplay=1';

                if ($('body').hasClass('mute')) {
                    src += '&mute=1&vol=0';
                }

                $video.removeAttr('height').removeAttr('width');
                $video.width(newWidth).height(newWidth * aspectRatio);
                $video.attr('src', src);

                $video[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*'); // Play the video
            },
            onHidden: function () {
                var $container = $(this).find('.video-container'),
                    $video = $container.find('iframe');

                $video[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*'); // Stop the video
            }
        }).modal('show');
    };

    /**
     * Show the package modal
     * @param pakketId
     */
    var showPakketModal = function (pakketId) {
        var $pakketModal = $('.pakket-modal');

        $('.pakket-container', $pakketModal).hide();
        $('.pakket-' + pakketId, $pakketModal).show();

        $pakketModal.modal({
            context: '#page',
            transition: 'vertical flip',
            dimmerSettings: {
                opacity: 0.5
            },
            onHide: function () {
                $('.pakket-video-modal').modal('hide');
            }
        }).modal('show');
    };

    // Public methods
    return {
        init: init,
        addProductChevrons: addProductChevrons,
        etiketModalOnVisible: etiketModalOnVisible
    };
})(jQuery, window, document);