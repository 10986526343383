/**
 * Cart module.
 *
 * @author Jason Koolman <https://github.com/Jasonkoolman>
 */
var Cart = (function ($, window, document) {
    var $cart = $('#cart');
    var $cartTabs = $('.cart-tabs', '#cart');
    var $tabs = $cart.find('.tab');
    var $steps = $cart.find('.step');
    var $egv = $('#egv');
    var $egvNav = $('#egv-nav');
    var $productCategories = $('.product-category', '#egv');
    var $egvTeveel = $('#egv-teveel');
    var $navItems = $egvNav.find('li');
    var egvNavListenersBinded = false;
    var $buttonPrev = $('button.prev', 'nav.cart-tab-nav');
    var $buttonNext = $('.right.green', 'nav.cart-tab-nav');

    /**
     * Initialize the use of the cart.
     */
    var init = function () {
        loadTabs();
        setEvents();

        // Init product popups
        $('.product-title, .daypart-free-article').popup({
            position: 'bottom left'
        });

        // Tab toggling
        $('.toggle-button').click(function () {
            document.location.hash = '#gift';
            $(this).toggleClass('toggled').parents('.tab').find('.toggle-container').slideToggle();
        });

        // Table toggling
        $('.toggle-table').click(function () {
            $(this).toggleClass('toggled').siblings('table').toggleClass('toggled');
        });

        // DayParts
        $('.ui.accordion', '#data-form').accordion();
        $('.ui.checkbox', '#data-form')
            .checkbox({
                onChange: function () {
                    if (this.name == 'dagdeel_code') {
                        var $dagdeel = $(this);
                        $('#dagdeel').html($dagdeel.next().html());
                        $dagdeel.closest('.content').prev().accordion('close');
                    }
                }
            });

        var $fldNumberControl = $('#form_field_telefoon_control');
        if ($fldNumberControl.length) {
            $('#form_field_telefoon')
                .on('keyup', function () {
                    if (this.value.substr(0, 2) === '06') {
                        $fldNumberControl.closest('.field').show();
                        $fldNumberControl.attr('required', 'required');
                    } else {
                        $fldNumberControl.closest('.field').hide();
                        $fldNumberControl.removeAttr('required');
                    }
                })
                .trigger('keyup');
        }

        var $fldEmailControl = $('#form_field_email_control');
        if ($fldEmailControl.length) {
            $('#form_field_email_2')
                .on('keyup', function () {
                    if ($('#form_field_telefoon').val().substr(0, 2) !== '06') {
                        $fldEmailControl.closest('.field').show();
                        $fldEmailControl.attr('required', 'required');
                    } else {
                        $fldEmailControl.closest('.field').hide();
                        $fldEmailControl.removeAttr('required');
                    }
                })
                .trigger('keyup');
        }

        // Modal Voorwaarden
        $('.ui.modal.modal-voorwaarden', '#data-form')
            .modal('setting', {
                onApprove: function () {
                    $('#data-form').find('input[name="voorwaarden"]').prop('checked', true);
                }
            });
        $('#voorwaarden').click(function (e) {
            e.preventDefault();

            $('.ui.modal.modal-voorwaarden').modal('show');
        });

        // Modal Share
        $('.ui.modal.modal-share')
            .modal('setting', {
                onApprove: function () {
                    // this = modal

                    var modal = this;
                    var $form = $('form', modal);
                    var form = $form[0];

                    $form.find('input').popup({
                        on: 'manual',
                        position: 'top right',
                        onShow: function (module) {
                            setTimeout(function () {
                                $(module).popup('hide');
                            }, 2000)
                        }
                    });

                    var valid = true;

                    if (form.naam.value == '') {
                        $(form.naam).popup('show');
                        valid = false;
                    }
                    if (form.email.value == '') {
                        $(form.email).popup('show');
                        valid = false;
                    }
                    if (form.naam_vriend.value == '') {
                        $(form.naam_vriend).popup('show');
                        valid = false;
                    }
                    if (form.email_vriend.value == '') {
                        $(form.email_vriend).popup('show');
                        valid = false;
                    }

                    if (valid) {
                        var $form = $(form);
                        $form.addClass('loading');
                        var data = $form.serialize();
                        $.post(AJAX, data, function (data) {
                            if (data.success) {
                                $('.share-bedankt', modal)
                                    .append('<p>Bedankt. ' + form.naam_vriend.value + ' heeft jouw bestellijst ontvangen.</p>')
                                    .slideDown();
                            } else if (data.error) {
                                for (var field in data.fields) {
                                    if (field == 'ww') {
                                        $('.share-bedankt', modal)
                                            .append('<p>Je moet nog artikelen toevoegen aan je bestellijst.</p>')
                                            .slideDown();
                                    } else if (field == 'database' || field == 'mailer') {
                                        $('.share-bedankt', modal)
                                            .append('<p>Er is een onverwachte fout opgetreden. Probeer het nogmaals.</p>')
                                            .slideDown();
                                    } else {
                                        $(form[data.fields[field]]).popup('show');
                                    }
                                }
                            } else {
                                $('.share-bedankt', modal)
                                    .append('<p>Helaas. Er ging iets mis. Probeer het nogmaals.</p>')
                                    .slideDown();
                            }
                        }).fail(function (data) {
                            window.console && console.log(data);

                            $('.share-bedankt', modal)
                                .append('<p>Helaas. Er ging iets mis. Probeer het nogmaals.</p>')
                                .slideDown();
                        }).done(function () {
                            $form.removeClass('loading');
                        });
                    }

                    return false;
                }
            })
            .find('input').on('keyup', function (e) {
                if (e.which == 13) {
                    $(this).closest('.ui.modal').find('.positive.button').trigger('click');
                }
            });

        // Only
        if (activeTab() == 'cart') {
            $('.cart-only', '#cart').css('display', 'inline-block');
        } else if (activeTab() == 'information') {
            $('.information-only', '#cart').css('display', 'inline-block');
        }

        // Prev button
        togglePrevButton(activeTab());

        // Payment
        $('.ui.checkbox', '#payment-form')
            .checkbox({
                onChange: function () {
                    $('#payment-form').find('div.field').removeClass('active');
                    $(this).closest('div.field').addClass('active');
                }
            });

        // Gifts
        if (document.location.hash == '#gift' || $('.ui.tab.do-toggle').length) {
            $('.toggle-button', 'header').trigger('click');
        }

        // Gifts
        if (activeTab() == 'gift') {
            $egv.show();
            $egvNav.show();
            bindEgvNavListeners();
        }

        // Apple Pay
        if ($('.bm_apple_pay').length) {
            if (canMakePaymentsWithApplePay()) {
                $('.bm_apple_pay').css('display', 'flex');
            }
        }
    };

    var togglePrevButton = function (activeTab) {
        if (activeTab == 'cart') {
            if ($buttonPrev.html().indexOf('Verder winkelen') >= 0 && $buttonNext.html().indexOf('Verder winkelen') >= 0) {
                $buttonPrev.hide();
                return;
            }
        }

        $buttonPrev.show();
    };

    /**
     * Load the tabs.
     */
    var loadTabs = function () {
        // Init tabs
        $.tab({
            onLoad: function (path) {
                var $step = $steps.filter(function () {
                    return $(this).data('tab') === path;
                });
                $step.prevAll('.step').addClass('completed');
                $step.nextAll('.step').add($step).removeClass('completed');
                $step.addClass('active').siblings().removeClass('active');
            }
        });

        // Manual navigation
        $('.cart-tab-nav', '#cart').find('.button').click(function () {
            var $button = $(this);
            if (! $button.hasClass('prev') && !$button.hasClass('next')) {
                return;
            }

            var $activeTab = $tabs.filter('.active');
            var $nextTab = $activeTab.next('.tab');
            var $prevTab = $activeTab.prev('.tab');
            var $targetTab;

            if ($button.hasClass('next') && $nextTab.length) {
                $targetTab = $nextTab;

                var productCount = $('.cart.icon label', '#header').html();
                if (productCount == 0) {
                    return false;
                }

                if (activeTab() == 'information') {
                    var $form = $('#data-form');
                    if (!$form.length || !$form.is(':visible')) {
                        $form = $('#login-form');
                    }

                    $form.trigger('submit');
                    return;
                }
            } else if ($button.hasClass('next') && activeTab() == 'payment') {
                var $form = $('#payment-form');
                $form.trigger('submit');
                return;
            } else if ($button.hasClass('prev') && activeTab() == 'cart') {
                if ($button.hasClass('invullijst')) {
                    document.location.href = '/invullijst/';
                } else {
                    document.location.href = '/';
                }
                return;
            } else if ($button.hasClass('prev') && activeTab() == 'overview') {
                document.location.href = '/bestellijst/information/';
                return;
            } else if ($prevTab.length) {
                $targetTab = $prevTab;
            }

            if (typeof $targetTab != 'undefined') {
                $.tab('change tab', $targetTab.data('tab'));

                togglePrevButton($targetTab.data('tab'));

                if ($targetTab.data('tab') == 'cart') {
                    if ($buttonPrev.hasClass('invullijst')) {
                        $buttonPrev.html('<i class="arrow left icon"></i> Verder winkelen');
                    } else {
                        $buttonPrev.html('Verder winkelen');
                    }
                } else {
                    $buttonPrev.html('Vorige stap');
                }

                if ($targetTab.data('tab') == 'cart') {
                    $('.cart-only', '#cart').css('display', 'inline-block');

                    _gaq.push(['_trackPageview', '/bestellijst/']);
                } else {
                    $('.cart-only', '#cart').hide();
                }

                if ($targetTab.data('tab') == 'information') {
                    $('.information-only', '#cart').css('display', 'inline-block');

                    _gaq.push(['_trackPageview', '/bestellijst/information/']);
                } else {
                    $('.information-only', '#cart').hide();
                }

                if ($targetTab.data('tab') == 'overview') {
                    _gaq.push(['_trackPageview', '/bestellijst/overview/']);
                }

                if ($targetTab.data('tab') == 'payment') {
                    $('button.next', '#cart').children('span').html('Betalen');

                    _gaq.push(['_trackPageview', '/bestellijst/payment/']);
                } else {
                    $('button.next', '#cart').children('span').html('Volgende stap');
                }

                if ($targetTab.data('tab') == 'information') {
                    $('html, body').scrollTop(0, 0);
                }

                if ($targetTab.data('tab') == 'gift') {
                    $egv.show();
                    $egvNav.show();
                    bindEgvNavListeners();
                    ProductOverview.addProductChevrons();

                    _gaq.push(['_trackPageview', '/bestellijst/gratis-vuurwerk/']);
                } else {
                    $egv.hide();
                    $egvNav.hide();
                    bindEgvNavListeners('remove');
                }

                $('.cart-tab-nav', '#cart').attr('data-tab-active', $targetTab.data('tab'));
            }
        });
    };

    var activeTab = function () {
        var $activeTab = $steps.filter('.active');
        if ($activeTab.length) {
            return $activeTab.data('tab');
        }
        return '';
    };

    var setEvents = function () {
        var $cartHeader = $('.cart.button', 'header');

        $cart.on('click', 'button.button-plus, button.button-min', function buttonPlusMinClick(e) {
            var $button = $(this);
            var $aantal = $button.parent().find('span');
            var plusMin = $button.hasClass('button-min') ? -1 : 1;
            var $product = $button.closest('tr');
            var artikelId = $product.data('id');
            var type = '';
            if ($product.hasClass('egv')) {
                type = 'egv';
            }

            e.preventDefault();

            // verplichte vuurwerkpijlstandaard
            if (artikelId == 3380 && plusMin == -1 && $aantal.text() == 1 && $cart.attr('data-ww-has-vuurwerkpijl') == '1') {
                // vragen of standaard al in bezit is
                $('.ui.modal.modal-vuurwerkpijlstandaard')
                    .modal({
                        onApprove: function () {
                            // todo; cache in cookie/session?
                            postArtikelData();
                        }
                    })
                    .modal('show');
            } else {
                postArtikelData();
            }

            function postArtikelData() {
                $product.css('opacity', .5);

                $cartHeader.find('.icon').transition('tada');

                var data = {'artikel_id': artikelId, 'actie': 'wijzigen', 'aantal': plusMin, 'type': type};
                $.post(AJAX, data, function (data) {
                    if (!data.artikelId) {
                        window.console && console.log(data);
                    }

                    if ((data.totaalBedrag < MINIMAALBESTELLEN && TOTAALBEDRAG >= MINIMAALBESTELLEN)
                        || (data.totaalBedrag >= MINIMAALBESTELLEN && TOTAALBEDRAG < MINIMAALBESTELLEN)
                        || (data.totaalBedrag == 0 && TOTAALBEDRAG > 0)
                        || (data.artikelId != $product.data('id'))
                        || (data.actieArtikelId)
                    ) {
                        $cartTabs.css('opacity', .5);
                        document.location.href = BASEPATH + 'bestellijst/';
                        return;
                    }

                    // facebook-actie artikel
                    // todo; ook het gratis artikel bij /actie/ACTIE krijgt momenteel facebook_actie === true
                    var fbArtikel = false;
                    for (var key in data.ga) {
                        if (data.ga[key].facebook_actie === true) {
                            fbArtikel = true;
                        }
                    }
                    if ($cart.find('tr[data-facebook-actie]').length) {
                        if (!fbArtikel) {
                            $cart.find('tr[data-facebook-actie]').fadeOut();
                        } else {
                            $cart.find('tr[data-facebook-actie]').fadeIn();
                        }
                    } else if (fbArtikel) {
                        $cartTabs.css('opacity', .5);
                        document.location.href = BASEPATH + 'bestellijst/';
                    }

                    TOTAALAANTAL = data.aantal;
                    TOTAALBEDRAG = data.totaalBedrag;

                    var artikelId = data.artikelId;

                    $cartHeader.find('.label').html(data.aantal);

                    $cart.find('td.totaalbedrag').html(price(data.totaalBedrag));
                    $cart.find('tfoot td.highlight span').html(price(data.gratisVuurwerkBedrag));
                    $('#egvOpbouwTonenMobile').html(price(data.gratisVuurwerkBedrag));

                    toggleCartButtons(data.ww, data.egv);

                    setEgvInfo(data.gratisVuurwerkBedragOver, data.gratisVuurwerkBedragTeveel);

                    var aantalNew = 0;
                    if (type == '') {
                        if (data.ww && data.ww[artikelId]) {
                            aantalNew = data.ww[artikelId].aantal;
                        }
                        if (aantalNew == 0) {
                            $product.fadeOut();
                        } else {
                            $aantal.html(aantalNew);

                            var $totaalprijs = $button.parent().next().next();
                            var $egvBedrag = $totaalprijs.next().find('span');

                            $totaalprijs.html(price(data.ww[artikelId].totaalprijs));
                            $egvBedrag.html(price(data.ww[artikelId].egvBedrag));
                        }
                    } else if (type == 'egv') {
                        if (data.egv && data.egv[artikelId]) {
                            aantalNew = data.egv[artikelId].aantal;
                        }
                        if (aantalNew && aantalNew == $aantal.html()) {
                            $egvTeveel.fadeTo(300, .5, function () {
                                $egvTeveel.fadeTo(300, 1, function () {
                                    $egvTeveel.fadeTo(300, .5, function () {
                                        $egvTeveel.fadeTo(300, 1);
                                    });
                                });
                            });
                        }
                    }

                    buildEgvRows(data.egv);

                    for (var wwKey in data.ww) {
                        $('tr[data-id="' + wwKey + '"]', '#cart').attr('data-aantal', data.ww[wwKey].aantal);
                    }

                    if (data.wwHasVuurwerkpijl) {
                        $('#cart').attr('data-ww-has-vuurwerkpijl', '1');
                    } else {
                        $('#cart').attr('data-ww-has-vuurwerkpijl', '0');
                    }
                }).fail(function (data) {
                    window.console && console.log(data);
                }).done(function () {
                    $product.css('opacity', 1);
                });
            }
        });

        var $btnShowDataDiv = $cart.find('.button-show-data-div');
        $btnShowDataDiv.click(function (e) {
            var $loginForm = $('#login-form');
            var $dataDiv = $('#data-div');
            var $dataForm = $('#data-form');

            $dataForm.find('input[name="email"]').val($loginForm.find('input[name="email"]').val());
            $dataForm.find('input[name="password"]').val($loginForm.find('input[name="password"]').val());

            var $button = $dataForm.find('button');
            if (! $button.length) {
                $dataForm.append('<button class="ui orange right floated button" type="submit"><i class="key icon"></i> Registreren</button>');
            }

            $dataDiv.show();
            $(this).hide();
            e.preventDefault();
        });
        if ($btnShowDataDiv.hasClass('bsdd-winkel')) {
            $btnShowDataDiv.trigger('click');
            $('.ui.orange.right.floated.button', '#cart').hide();
        }

        $('button', '#cart').click(function (e) {
            var $button = $(this);
            if (! $button.data('href')) {
                return;
            }

            if ($button.data('target')) {
                window.open($button.data('href'));
            } else if ($button.hasClass('share')) {
                $('.ui.modal.modal-share').modal('show');
            } else {
                document.location.href = $button.data('href');
            }
        });
    };

    /**
     * Binds the listeners.
     */
    var bindEgvNavListeners = function (action) {
        if (action == 'remove') {
            $('#cart.ui.sticky').sticky('destroy');
            egvNavListenersBinded = false;
            return;
        }

        if (egvNavListenersBinded) {
            return;
        }

        if (!$egvNav.is(':visible')) {
            return;
        }

        egvNavListenersBinded = true;

        $('#cart.ui.sticky')
            .sticky({
                offset: $('#header').height(),
                onStick: function() {
                    // if ($('.toggle-container', '#cart').is(':visible')) {
                    //     $('.toggle-button', 'header').trigger('click');
                    // }
                    $(this).css('height', '');
                },
                onUnstick: function() {

                }
            });

        // Clickable navigation
        $navItems.click(function(e) {
            var category = $(this).data('category');
            var $target = $('#' + category);

            // Set the location hash
            window.location.hash = category;

            // Scroll to category
            if ($target.length) {
                $('html, body').scrollTop($target.offset().top - $cartTabs.height() - $egvNav.height());
            }

            e.preventDefault();
        });

        // Listen to category scroll events
        $productCategories.visibility({
            continuous: true,
            offset: $('#header').height() + $cartTabs.height() + $egvNav.height(),
            onPassing: function(data) {
                var $element = $(this);

                // Check if the category being viewed is not active yet
                if ( ! $element.hasClass('active')) {
                    // Activate the category button
                    $navItems.removeClass('active')
                        .filter('[data-category="' + $element.data('category') + '"]').addClass('active');

                    // Mark the new category as active
                    $productCategories.removeClass('active')
                        .filter($element).addClass('active');
                }
            }
        });
    };

    // Public methods
    return {
        init: init
    };
})(jQuery, window, document);