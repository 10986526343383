/**
 * Koningsdal custom JavaScript.
 *
 * @author Jason Koolman <https://github.com/Jasonkoolman>
 */

var elementInViewport = function (el, addToTop) {
    var rect = el.getBoundingClientRect();

    if (!addToTop) {
        addToTop = 0;
    }

    return (
        rect.top >= addToTop && rect.left >= 0
        && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
};

(function($) {
    var $header = $('#header');
    var $page = $('#page');

    // Messages
    $('.message .close').click(function () {
        $(this).parent().remove();
    });

    // JAIL
    $('img.lazy', '#content').lazyload();

    // FAQ toggling
    $('.faq-item').find('.question').click(function () {
        $(this).parent().toggleClass('toggled').find('.answer').slideToggle();
    });

    // Header dropdown menu
    var $dropdown = $header.find('.dropdown');
    var $dropdownAccordion = $dropdown.find('.accordion');

    $dropdown.dropdown({
        duration: 350,
        action: 'nothing'
    });

    $dropdownAccordion.click(function () {
        var $item = $(this);
        $item.find('i').toggleClass('up down');
        $item.find('.list').slideToggle(300);
    }).find('.item').click(function () {
        $dropdown.dropdown('hide');
    });

    $('input', '#header-dropdown').on('keyup', function (event) {
        if (event.which === 13) {
            this.parentNode.submit();
        }
    });

    // Sticky footer
    function stickyFooter() {
        var windowHeight = $(window).height();

        if ($page.outerHeight(true) < windowHeight) {
            var minHeight =  $('#footer').outerHeight(true);

            $('#page-container').css('min-height', windowHeight - minHeight);
        }
    }
    stickyFooter();

    // DayParts Afhaalmoment
    $('.ui.accordion', '.afhaalmoment-order').accordion();
    $('.ui.checkbox', '.afhaalmoment-order')
        .checkbox({
            onChange: function () {
                if (this.name === 'dagdeel_code') {
                    var $dagdeel = $(this);
                    var $buttonGrid = $dagdeel.closest('form').children().not('.article').last();

                    $dagdeel.closest('.content').prev().accordion('close');

                    $buttonGrid.show();
                    $buttonGrid.find('button').html('Afhaalmoment wijzigen in; ' + $dagdeel.next().html());
                }
            }
        });

    // Afhaalmoment
    if ($page.hasClass('page-afhaalmoment')) {
        $('.afhaalmoment-order')
        // Alter date/time
            .on('click', 'a.alter', function (event) {
                var $div = $(this).closest('.afhaalmoment-order');
                $div.find('form').slideToggle();
                $div.find('div.article').slideUp();
            })

            // Articles
            .on('click', 'a.articles', function (event) {
                event.preventDefault();
                $(this).closest('.afhaalmoment-order').find('div.article').slideToggle();
            });

        if (document.location.hash) {
            var $div = $(document.location.hash);
            if ($div.hasClass('afhaalmoment-order')) {
                $div.find('div.article').slideDown();
            }
        }

        setTimeout(function () {
            $('div.opgehaald').each(function () {
                var $div = $(this);
                var $img = $div.next();
                if ($img.width() && $img.width() < $div.width()) {
                    $div.width($img.width());
                    $div.height($img.height());
                }
            });
        }, 1000);

        $('.product-title', $page).popup({
            position: 'bottom left'
        });
    }

    // Init product popups
    $('.product-title, .daypart-free-article').popup({
        position: 'bottom left'
    });

    // Forms
    $('form').on('submit', function () {
        $(this).addClass('loading');
    });

    function lazyBanners() {
        $('div.promotion', '#content').filter('[data-src]').each(function (index, element) {
            if (elementInViewport(element)) {
                $(element)
                    .css('background-image', 'url(' + element.getAttribute('data-src') + ')')
                    .removeAttr('data-src');
            }
        });

        $('iframe', '#content').filter('[data-src]').each(function (index, element) {
            if (elementInViewport(element)) {
                $(element)
                    .attr('src', element.getAttribute('data-src'))
                    .removeAttr('data-src');
            }
        });
    }

    lazyBanners();

    // Scroll listeners
    $(window).scroll(function () {
        stickyFooter();

        lazyBanners();
    });

    var $scherpeAanbiedingenCounter = $('#scherpe-aanbiedingen-counter');
    if ($scherpeAanbiedingenCounter.length) {
        if (window.innerWidth <= 543) {
            window.scrollTo(0, 330);
        }

        var $button = $('.green.button', 'nav.cart-tab-nav');
        $button.hide();

        var scherpeAanbiedingenInterval = setInterval(function () {
            var counter = $scherpeAanbiedingenCounter.html().replace('nog ', '') * 1;
            counter--;
            $scherpeAanbiedingenCounter.html(counter);

            if (counter === 0 || elementInViewport($scherpeAanbiedingenCounter.parent()[0], 40)) {
                $scherpeAanbiedingenCounter.removeClass('fixed');
            } else {
                $scherpeAanbiedingenCounter.addClass('fixed');
                $scherpeAanbiedingenCounter.html('nog ' + counter);
            }

            if (counter === 0) {
                clearInterval(scherpeAanbiedingenInterval);

                $('#button-geen-sa').trigger('click');
            }
        }, 1000);

        $('#button-geen-sa').on('click', function (event) {
            event.preventDefault();

            $('#scherpe-aanbiedingen').remove();
            $('#payment-extra-message').show();
            $button.show();

            window.scrollTo(0,0);

            $.post(AJAX, 'actie=geen-sa');
        });
    }

    /* maps
    $('#maps').one('click', function () {
        $('img', '#maps').remove();

        var mapsCanvas = document.getElementById('maps');
        var latLng = {lat: 52.075902, lng: 5.136983};

        // Options
        var mapsOptions = {
            center: latLng,
            zoom: 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true
        };

        // Map
        var map = new google.maps.Map(mapsCanvas, mapsOptions);

        // Marker
        new google.maps.Marker({
            position: latLng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: 'Koningsdal Vuurwerk'
        });
    });
    */

    // solliciteren
    $('#solliciteren-form')
        .on('click', '.ui.radio.checkbox label', function () {
            $(this).prev().trigger('click');
        })
        .on('change', 'input[type="file"]', function () {
            if (this.files.length) {
                var file = this.files[0];
                var $input = $(this);
                if (file.size > 8*1024*1024) {
                    $input.parent().addClass('error');
                    $input.after('<strong>Let op; dit bestand is te groot. De limiet is 8 MB.')
                } else {
                    $input.parent().removeClass('error');
                    $input.next('strong').remove();
                }
            }
        });

    $(document).on('keydown', function (event) {
        if (event.which === 69 && event.ctrlKey) {
            var $input = $('input:visible', '#header');
            if ($input.length) {
                $input.focus();
                return false;
            }
        }
    });

    // stay alive
    stayAliveCounter = 0;
    var stayAliveTimer = setInterval(function () {
        stayAliveCounter++;
        if (stayAliveCounter >= 60) {
            clearInterval(stayAliveTimer);
            return;
        }

        $.ajax({
            dataType: 'json',
            url: AJAX,
            data: 'actie=stay-alive',
            cache: false
        });
    }, 60000);

    // solliciteren
    if (document.getElementById('solliciteren-form')) {
        $('option[value=""]', '#form_field_functie').css('color', 'grey');
    }
})(jQuery);

function toggleCartButtons(ww, egv) {
    if (ww.length === 0) {
        $('button.cart-only', '#cart').addClass('ww-empty');
    } else {
        $('button.cart-only', '#cart').removeClass('ww-empty');
    }
}

function setEgvInfo(gratisVuurwerkBedragOver, gratisVuurwerkBedragTeveel) {
    var $egvTeveel = $('#egv-teveel');

    $('span', 'h3.header').html(price(gratisVuurwerkBedragOver));
    $('span', $egvTeveel).html(price(gratisVuurwerkBedragTeveel));
    if (gratisVuurwerkBedragTeveel > 0) {
        $egvTeveel.removeClass('hidden');
    } else {
        $egvTeveel.addClass('hidden');
    }
}

function buildEgvRows(egvArtikelen) {
    // egv lijst opnieuw opbouwen

    if (!$('#egv').length) {
        return;
    }

    var $egvTbody = $('#ga-egv-artikelen');
    var $egvRows = $('tr.highlight.egv', $egvTbody);
    if (egvArtikelen) {
        for (var artikelId in egvArtikelen) {
            var egv = egvArtikelen[artikelId];

            var $row = $egvRows.filter('[data-id="' + artikelId + '"]');
            if (!$row.length) {
                var html = '<tr class="highlight egv" data-id="' + artikelId + '" style="opacity: 0">';
                html += '<td>' + egv.naam + '</td>';
                html += '<td>';
                html += '<button class="ui circular icon button button-plus"><i class="plus icon"></i></button>';
                html += ' <span></span> ';
                html += '<button class="ui circular icon button button-min"><i class="minus icon"></i></button>';
                html += '</td>';
                html += '<td></td>';
                html += '<td></td>';
                html += '</tr>';

                $egvTbody.append(html);
                $row = $egvTbody.find('tr[data-id="' + artikelId + '"]');
                $row.fadeTo(300, 1);
            }

            var $tds = $row.children('td');

            $tds.eq(1).children('span').html(egv.aantal);
            $tds.eq(2).html(price(egv.verkoopprijsOrigineel));
            $tds.eq(3).html((egv.totaalprijs == 0) ? 'GRATIS' : price(egv.totaalprijs));
        }

        $egvRows.each(function () {
            var $row = $(this);
            var artikelId = $row.data('id');

            if (!egvArtikelen[artikelId]) {
                // egv artikel is verwijderd
                $row.fadeOut(300, function () {
                    $(this).remove();
                });
            }
        });
    } else {
        $egvRows.fadeOut(300, function () {
            $egvRows.remove();
        });
    }
}

function price(number, euro) {
    euro = (euro) ? '' : '&euro; ';

    return euro + number_format(number, 2, ',', '.');
}

function number_format (number, decimals, decPoint, thousandsSep) {
    //  discuss at: http://locutus.io/php/number_format/
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number;
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint;

    var toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + (Math.round(n * k) / k)
                .toFixed(prec)
    };

    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    var s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(dec);
}

/**
 * Check that the Apple Pay JS API is available in the browser,
 * and that payments with Apple Pay are possible.
 *
 * @returns {boolean} true if Apple Pay payments are possible, false otherwise.
 * @see <a href="https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability">Checking for Apple Pay Availability</a>
 */
function canMakePaymentsWithApplePay() {
    if (window.ApplePaySession) {
        try {
            if (ApplePaySession.canMakePayments()) {
                return true;
            } else {
                // console.log("Can not make Apple Pay payments.");
            }
        } catch (e) {
            // console.log("Starting an Apple Pay session requires a secure document.", e);
        }
    } else {
        // console.log("Apple Pay JS API is not available.");
    }

    return false;
}